import { LOCALE_CODES, LOCALES } from '@whispli/i18n/constants'
import type { LocaleCode } from '@whispli/i18n/types'
import { DayjsAdapter } from '@whispli/ui/i18n/adapters/dayjs'
import { VueRouterAdapter } from '@whispli/ui/i18n/adapters/vue-router'
import { VeeValidateAdapter } from '@whispli/ui/i18n/adapters/vee-validate'
import { VueI18nAdapter } from '@whispli/ui/i18n/adapters/vue-i18n'
import { VuetifyAdapter } from '@whispli/ui/i18n/adapters/vuetify'
import { UiAdapter } from '@whispli/ui/i18n/adapters'
import {
  ChangeLocaleFactory, find as _find, LoadFactory,
} from '@whispli/ui/i18n/utils'
import memoizeWith from 'ramda/es/memoizeWith'
import toString from 'ramda/es/toString'
import { getInitialLocale } from '@whispli/i18n/utils/get-initial-locale'

/** Track previously loaded locales */
const LOADED_LOCALES = new Set<LocaleCode>([])

/** A list of third-party dependencies requiring or providing localization */
const ADAPTERS = Object.freeze([
  VueRouterAdapter,
  VueI18nAdapter,
  VeeValidateAdapter,
  DayjsAdapter,
  VuetifyAdapter,
  UiAdapter,
])
export const load = LoadFactory(ADAPTERS, LOCALES, LOADED_LOCALES)

export const find = memoizeWith(toString, _find.bind(null, LOCALES))

export const changeLocale = ChangeLocaleFactory(ADAPTERS, LOCALES, LOADED_LOCALES, load)

export const setInitialLocale = () => changeLocale(getInitialLocale(LOCALE_CODES))

export const modules = import.meta.glob([ './messages/*.json', '!./messages/*.source.*' ], { import: 'default' })
