import { useI18n } from '@whispli/i18n/composables/useI18n'
import { API_TO_PHRASE } from '@whispli/i18n/constants'
import type { LocaleCode } from '@whispli/i18n/types'
import type { LocaleMessageObject } from 'vue-i18n'
import type VueI18n from 'vue-i18n'

/**
 * A map of locale keys to import functions.
 */
type LocaleModules = Record<string, () => Promise<unknown>>

/**
 * useImportLocale exposes helpers for loading translation messages
 * and making them available to Vue components.
 *
 * @param modules a map of locale keys to import functions
 * (e.g. `import.meta.glob([ './messages/*.json', '!./messages/*.source.*' ])`
 * */
export const useImportLocale = (modules: LocaleModules) => {
  const { i18n } = useI18n()

  /**
   * Load local messages for a given local into the Vue `i18n` object.
   */
  const load = async (locale: string): Promise<void> => {
    if (!i18n) {
      throw new Error('$i18n not initialized')
    }

    const localeMessages = await importLocale(modules, locale)
    if (!localeMessages) {
      throw new Error(`No translations for locale: ${locale}`)
    }

    mergeLocaleMessages(i18n, locale, localeMessages)
  }

  return { load }
}

/**
 * Import the i18n mappings for a given locale.
 */
const importLocale = async (
  modules: LocaleModules,
  locale: LocaleCode,
) => {
  const normalizedLocale = API_TO_PHRASE[locale] || locale

  // find the messages JSON import function for the given locale
  const messagesImportFnKey = Object.keys(modules).find(m => m.includes(`messages/${normalizedLocale}.json`))
  if (!messagesImportFnKey) {
    return
  }

  const module = await modules[messagesImportFnKey]?.() ?? {}

  return parseModule(module)
}

const parseModule = (module: unknown) => {
  try {
    return JSON.parse(JSON.stringify(module))
  } catch {
    console.error('Failed to parse a Phrase module')
    return {}
  }
}


/**
 * Merge messages for a given locale.
 *
 * An alternative to vue-i18n's `mergeLocaleMessage`.
 * This alternative _ignores_ `null` values for keys that already have translations.
 *
 * @link https://kazupon.github.io/vue-i18n/api/#mergelocalemessage-locale-message
 */
const mergeLocaleMessages = (i18n: VueI18n, locale: string, localeMessages: LocaleMessageObject) => {
  const oldMessages = i18n.messages[locale] ?? {}

  for (const message of Object.keys(localeMessages)) {
    const m = message as string
    if (oldMessages?.[m]) {
      continue
    }
    oldMessages[m] = localeMessages[m]
  }

  i18n.setLocaleMessage(locale, oldMessages)
}
