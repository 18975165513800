import { i18n } from '@whispli/i18n/plugins/vue-i18n'
import { computed, getCurrentInstance } from 'vue'
import type { LocaleCode } from '@whispli/i18n/types'
import { DEFAULT_LOCALE_CODE } from '@whispli/i18n/constants'

/**
 * @deprecated Vue 2 compatibility only. Remove after vue-i18n upgrade
 */
export const useI18n = () => {
  const _i18n = getCurrentInstance()?.proxy?.$i18n ?? i18n // ?? new Vue({}).$i18n

  return {
    i18n: _i18n,
    locale: computed<LocaleCode>(() => _i18n!.locale ?? DEFAULT_LOCALE_CODE),
    d: _i18n!.d.bind(_i18n),
    n: _i18n!.n.bind(_i18n),
    // @ts-ignore
    t: _i18n!.t.bind(_i18n),
    // @ts-ignore
    tc: _i18n!.tc.bind(_i18n),
    // @ts-ignore
    te: _i18n!.te.bind(_i18n),
  }
}
