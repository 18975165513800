import { type EventHint, getCurrentHub } from '@sentry/vue'
import type { AppError } from '@whispli/error'
import { __debug__ } from '@whispli/utils/debug'
import { isDevelopment, isProduction } from '@whispli/utils/env'
import type { Store } from 'vuex'
import { isNavigationFailure } from 'vue-router'

type ErrorHandlerFn = (error: AppError) => void

export default (
  err: Error | string | null | undefined | ReadonlyArray<Error | string | null | undefined> | unknown,
  log: boolean = isDevelopment,
  setBreakpoint: boolean = true,
  onContinue?: ((error: Error | any) => void) | undefined,
  hint?: EventHint
): void => {
  __debug__(err, log, setBreakpoint, onContinue)

  if (!log || !isProduction) {
    return
  }

  const client = getCurrentHub()?.getClient()

  if (client) {
    if (err instanceof Error) {
      client.captureException(err, hint)
    } else if (typeof err === 'string') {
      client.captureMessage(err, undefined, hint)
    }
  }
}

// @todo Support pinia store
export const errorHandler = (store?: Store<any>): ErrorHandlerFn => (error) => {
  if (isNavigationFailure(error)) {
    return
  }

  /**
   * @note Call original __debug__ only; Sentry integration already captures any Exception
   * propagated to root Vue instance.
   */
  __debug__(error, !isProduction, true)

  try {
    // store?.commit('setAppError', error)
  } catch (err) {
    // void
  }
}
