/* eslint-disable @typescript-eslint/no-parameter-properties */

import type {
  ERROR_ENUM,
  ERROR_STATUS_CODE_ENUM,
  ERROR_STATUS_CUSTOM_CODE_ENUM,
} from './constants'

export class AppError extends Error {
  public readonly name: string = 'AppError'

  constructor(
    public readonly message: string,
    public readonly status?: ERROR_ENUM | string,
    public readonly statusCode?: ERROR_STATUS_CODE_ENUM | ERROR_STATUS_CUSTOM_CODE_ENUM | number,
    public readonly originalError?:
    | Readonly<Error | Record<string, any>>
    | ReadonlyArray<Error | Record<string, any>>
    | unknown,
    public readonly data?: Record<string, any>,
  ) {
    super(message)
  }
}
export default AppError

